@import '../../assets/scss/variables.scss';

.fileAttachmentPreview{
    width: 100%;
    margin-top: 10px;
    .fileAttachments{
        display: flex;
        flex-direction: column;
        .fileItem{
            margin-right: 10px;
            margin-bottom: 10px;
            // margin-top: 10px;
            &.fileItemImage{
                position: relative;
                height: 100px;
                width: 170px;
                .imageWrapper{
                    img{
                        object-fit: cover;
                        height: 100px;
                        width: 170px;
                    }
                    height: 100px;
                    position: relative;
                    .overlay{
                        background: rgba(0,0,0,0.7);
                        position: absolute;
                        bottom: 0;
                        height: 25px;
                        width: 100%;
                        z-index: 1;
                        display: flex;
                        transition: 1s ease;
                        justify-content: space-between;
                        align-items: center;
                        padding-right: 10px;
                        padding-left: 10px;
                        span{
                            line-height: unset;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            .icon{
                                z-index: 2;
                                color: white;
                                font-size: 14px;
                                &.downloadIcon{
                                    margin-left: 5px;
                                }
                            }
                        }
                        .fileName{
                            color: white;
                            font-size: 11px;
                        }
                        .fileDownloadRemoveWrapper{
                            width: 40px;
                            display: flex;
                            justify-content: flex-end;
                        }
                    }
                }
                &:hover{
                    .overlay{
                        display: flex;
                    }
                }
            }
            &.fileItemDocument{
                border: 1px solid #ececec;
                display: flex;
                align-items: center;
                padding: 5px 10px;
                // height: 100px;
                .descriptionIcon{
                    color: $secondaryMain;
                    display: inline-block;
                    margin-right: 10px;
                }
                p{
                    margin-top: 0;
                    margin-bottom: 0;
                }
                .fileMetaDetails{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .fileMetaDetailsData{
                        .fileName{
                            max-width: 170px;
                            font-size: 11px;
                            // word-break: break-all;
                        }
                        .fileSize{
                            color: $grayLight;
                            font-size: 11px;
                        }
                    }
                    .fileMetaDetailsActions{
                        display: flex;
                        align-items: center;
                        span{
                            height: auto;
                            line-height: unset;
                            display: flex;
                            align-items: center;
                            &:first-child{
                                margin-right: 10px;
                            }
                        }
                        .icon{
                            z-index: 2;
                            color: $grayLight;
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }
    .fileAttachmentDetails{
        display: flex;
        .fileAttachmentCount{
            color: $secondaryMain;
        }
        .downloadBtn{
            color: $secondaryMain;
        }
    }

}