.normal-table th{position: relative;}
.normal-table .column-filter{position:absolute;top:5px;left:35px}
.column-filter .MuiInputBase-root{background-color:#fff; height:36px;}
.column-filter .MuiInputBase-root input,.column-filter .MuiInputBase-root .MuiSvgIcon-root{color:#32c2d9;}

.download-link{display:inline-block;background-color:#fff;border:1px solid #ddd;border-radius:5px;padding:5px 10px;text-decoration:none;margin:0 10px 10px 0;}
.download-link *{color:#333;text-decoration:none;}
.download-link:hover{background-color:#74d5e5;}
.download-link p{margin:0;}
.download-link span,.download-link > div{vertical-align:middle;}
$ball-size: 24px;
$font-family: 'Roboto', sans-serif;
$transition-duration: 0.2s;

.indicator-container {
  display: flex;
  align-items: center;
  margin: 8px 0;



  .ball {
    width: $ball-size;
    height: $ball-size;
    border-radius: 50%;
    margin: 4px 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }

  .label {
    font-family: $font-family;
    font-weight: bold;
    font-size: 14px;
    transition: all $transition-duration ease-in-out;
  }
}