.mail-modal-body{
    padding:20px;
    width:100%;
    height: 80vh;
    min-height: 500px;
    max-height: 650px;
    position: relative;
}
.insertZoomlink{
    margin: 8px !important;
}
// .wrapperClassName {
//     // Target the `rdw-link-decorator-wrapper` inside this wrapper
//     .rdw-link-decorator-wrapper {
//       a {
//           display: inline-block;
//           background-color: #007BFF;
//           color: white;
//           padding: 10px 20px;
//           text-decoration: none;
//           font-family: Arial, sans-serif;
//           font-size: 14px;
//           border-radius: 10px;
//           box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
//       }
  
//       span {
//         font-weight: bold; // Example: Style any spans within the decorator
//       }
//     }
//   }
.mail-modal-bottom{
    width:100%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    position: absolute;
    bottom:0;
    left:0
}

.MuiDialog-paper{
    overflow: hidden;
}
.rdw-option-wrapper {
    min-width: 8px !important;
    width:8px !important;
    height:8px !important;
    min-height: 8px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 10px !important;
}

.MuiOutlinedInput-input {
    padding: 8px 16px !important;
}

.rdw-editor-toolbar {
    font-size: 12px !important;
}