@import '../../../assets/scss/variables.scss';


.fileAttachmentModal{
    .filePicker{
        p{
            cursor: pointer;
        }
        display : flex;
        align-items : center;
        padding: 140px 20px;
        border-radius: 4px;
        border: 4px dashed #D8D8D8;
        background: $secondaryLight;
        text-align: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
        .highlighter{
            color: $secondaryMain;
        }
    }
    .fileBrowserWrapper{
        min-height: calc( 100vh - 333px);
    }
    .loaderWrapper{
        min-height: calc( 100vh - 170px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fileBrowser{
        .fileItemsWrapper{
            min-height: calc( 100vh - 340px);
            max-height: calc( 100vh - 340px);
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}