.dashboardItem{
    background: white;
    position: relative;
    max-height: 452px;
    overflow:hidden;
    .dashboardItemHeaderWrapper{
        padding: 15px;
        .dashboardItemHeader{
            margin: 0;
        }
        border-bottom: 1px solid #EDEDED;
    }
    .dashboardItemContentWrapper{
        &.setMinHeight{
            min-height: 380px;
        }
        &.center{
            display: flex;
            align-items: center;
            justify-content:center;
        }
        &.withPadding{
            padding: 5px;
        }
    }
    .lineLoader{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}


@media screen and (max-width: 1282px)
{
    .dashboardItem{
        max-height: none;
        overflow-y:auto;
    }
}