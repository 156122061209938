@import "../../../assets/scss/variables.scss";

.ml30{margin-left:30px;}

.desktopMenuSidebar,
.mobileMenuSidebar {
    .davidLogo {
        height: auto;
        width: 68%;
        display: block;
        padding: 20px 0 40px 30px;
        max-width: 70%;
        &.davidLogoSmallSize {
            padding: 6px 0 20px 0px !important;
            max-width: 50%;
        }
    }
    .MuiButtonBase-root {
        padding-left: 30px;
        transition: 0.5s ease !important;
        .sideBarIconWrapper {
            color: white;
            min-width: auto;
            margin-right: 15px;
        }
        .sideBarText {
            span {
                font-size: 14px;
            }
        }
        &.centerIcons {
            padding-left: 15px;
            // flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            .sideBarText {
                span {
                    font-size: 9px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: break-spaces;
                    width: 40px;
                }

                // display: none;
            }
            .sideBarIconWrapper {
                text-align: center;
                min-width: 0;
                color: white;
                margin-right: 0;
                svg {
                    font-size: 30px;
                }
            }
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .centerIcons {
        &.davidLogo {
            padding: 22px 0 30px 0px;
            width: 82%;
            margin: 0 auto;
        }
    }

    .activeSubmenuIndicator {
        background-color: $primaryLight;
    }

    // for styling inbox and compsoe mail link active indicator in sidebar
    .navLinkButton {
        &.active {
            &.inboxComposeDifferentiator {
                &.innerMenuItems {
                    // for styling active item without submneu
                    .MuiListItem-button {
                        background-color: $primaryLight;
                    }
                }
            }
        }
    }

    // styling unread count of inbox menu
    .inboxUnreadCount {
        background: $InProgress;
        padding: 1px 4px;
        border-radius: 3px;
        margin-right: 10px;
    }

    .customMenuIcon{
        &.outboxIcon{
            position: relative;
            left: 3px;
            margin-right: 4px;
        }
    }

    .sidebarBottom{
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 20px;
        .sideBarBottomText{
            margin-bottom: 20px;
            margin-top: 5px;
            
        }
        .sidebarBottomLinks{
            padding-left: 0;
            li{
                display: inline-block;
                &:last-child{
                    margin-left: 15px;
                }
            }
        }
    }
   
}
.desktopMenuSidebarInner,.mobileMenuSidebarInner{
    overflow-x: hidden;
}

.extraMenu {
    width: 300px;
    z-index: 2000;
    left: 1px !important;
    .extraMenuItem {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-bottom: 10px;
        padding: 20px 20px 0 20px;
        p {
            margin: 0;
        }
        .extraMenuIcon {
            height: auto;
            width: 50px;
            color: $primaryMain;
        }
        // Removing margin bottom fro last 3 elements
        &:nth-last-child(-n + 2) {
            margin-bottom: 0px;
            padding: 20px 20px 20px 20px;
        }
    }
}
.customButton {
    transition: width 0.3s ease; 
  
   
    width: 65px;
  }
  
  
  .customButton.expanded {
    width: 315px;
  }
.navLinkButton {
    &.composeMailLink {
        .composeMailLinkWrapper {
            display: flex;
            justify-content: center;
        }
        .composeMailItemContainer {
            padding-left: 15px;
            padding-right: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            background: $secondaryMain !important;
            .sideBarText {
                flex: none;
            }
            .sideBarIcon {
                font-size: 20px;
                margin-right: 0;
            }
        }
    }
}


.sidebarCollapsed{
    .composeMailItemContainer {
        padding: 8px 25px;
        flex-direction: column;
        border-radius: 10px;
        width: 100%;
        .sideBarText {
            flex: none;
            span{
                width: 30px!important;
            }
        }
    }
}