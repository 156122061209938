$themeGray: #777777;
$themeGrayDark: #787878;

.pageWrapper {
    padding: 26px 26px 26px 26px;
    // padding-top: 0;
    // border: 1px solid red;
    .pageHeaderWrapper {
        display: flex;
        justify-content: space-between;
        // align-items: flex-start;
        flex-wrap: wrap;
        // Note : consider changing to typography :: remove this if changing to typography - starts
        .pageHeaderDetails {
            h3 {
                // font-size: 18px;
                margin-bottom: 0;
                margin-top: 0;
                font-weight: 500;
                margin-right: 10px;
            }
            p {
                margin-top: 4px;
                margin-bottom: 0;
                color: $themeGray;
                // font-size: 12px;
            }
        }
        // Note : consider changing to typography :: reove this if changing to typography - ends

        .breadCrumbs {
            display: flex;
            align-items: flex-end;
            // margin-top: 10px;
            a {
                font-size: 12px;
            }
            .MuiBreadcrumbs-separator {
                font-size: 13px;
            }
        }
    }

    // --- Starts  : pageContentPaddingDecider classes --

    .pageContentlow {
        padding-top: 10px;
    }
    .pageContentMedium {
        padding-top: 15px;
    }
    .pageContentZero {
        padding-top: 0;
    }

    // --- Ends  : pageContentPaddingDecider classes --

    // @media screen and (min-width: 1921px) {
    //     padding: 20px;
    //     .pageHeaderWrapper {
    //         // Note : consider changing to typography :: remove this if changing to typography - starts
    //         .pageHeaderDetails {
    //             h3 {
    //                 font-size: 32px;
    //             }
    //             p {
    //                 font-size: 24px;
    //             }
    //         }
    //         // Note : consider changing to typography :: reove this if changing to typography - ends
    //         .breadCrumbs {
    //             a {
    //                 font-size: 24px;
    //             }
    //             .MuiBreadcrumbs-separator {
    //                 font-size: 26px;
    //             }
    //         }
    //     }
    // }
}

@media screen and (max-width: 610px) {
    .mainLayout {
        .pageWrapper {
            padding: 20px !important;
        }
    }
}
