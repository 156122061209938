@import '../../assets/scss/variables.scss';


.linkButton{
    text-decoration: none!important;
    a{
        text-decoration: none;
    }
}

.navLinkButton{
    text-decoration: none!important;
    a{
        text-decoration: none;
    }
    // For styling active item in sidebar
    &.active{

        // for styling active item without submneu 
        .MuiListItem-button{
            background-color: $primaryLight;
        }

        // styling inner menu items background egs: items coming inside mail
        &.innerMenuItems{
            // for styling active item without submneu 
            .MuiListItem-button{
                background-color: #535CC2;
            }
        }

        

        // for styling active item inside a submenu 
        .subMenuItem{
            background-color: $secondaryMain;
            padding: 2px 15px;
            border-radius: 20px;
        }

    }    
}


.customButton{
    .loader{
        height: auto!important;
        width: 13px!important;
        margin-right: 4px;
    }
    // .buttonContent{
    //     position: relative;
    //     display: flex;
    //     align-items: center;
    //     .loader{
    //         height: auto!important;
    //         width: 13px!important;
    //         margin-right: 4px;
    //         position: absolute;
    //         left: -20px;
    //         &.loaderHidden{
    //             display: none;
    //         }
    //         &.loaderShown{
    //             display: block;
    //         }
    //     }
    // }
    text-transform: none;
    &.Mui-disabled{
        background-color: rgba(0, 0, 0, 0.12)!important;
    }
}


.iconButton{
    &.disableHoverEffect{
        background: transparent;
        &:hover{
            background: transparent;
        }
    }
}