
@import '../../assets/scss/variables.scss';
.surveyStatusWrapper{
    width: 100%;
    margin-top: 10px;
    .surveyStatusContent{
        height: 57px;
        border-radius: 4px;
        border: solid 1px #8ddde6;
        background-color: #f2fdff;
        display: inline-flex;
        width: 100%;
        margin-bottom: 10px;
        border-bottom-right-radius: 50px;
        border-top-right-radius: 50px;
        cursor: pointer;
    }
    .surveyStatusContentWarning{
        background-color: #dcfaff;
    }
    .surveyStatusContentDone{
        background-color: #c5f6ff;
    }
    .statusLabel{
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
    }
    .surveyStatusMsg{
        // display: flex;
        // flex-direction: row-reverse;
        display: flex;
        // justify-content: space-between;
        flex-direction: row-reverse;
    }
    .surveyStatusMsgText{
        display: flex;
        align-items: center;
        margin-right: 10px;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: normal;
        text-align: right;
        color: #171717;
    }
    .surveyStatusMsgTextWarning{
        color: #f44336;
    }
    .surveyStatusMsgTextDone{
        font-weight: 500
    }
    .surveyStatusMsgCircle{
        height: 56px;
        width: 56px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: -2px;
        color: #ffffff;
        background-color: #48def2;
    }
    .surveyStatusMsgCircleWarning{
        background-color: #f44336;
    }
    .surveyStatusMsgCircleDone{
        background-color: #32c2d9;
    }
    .surveyStatusItem{
        margin-left: 15px;
        width: 100%
    }
    .surveyStatusLabel{
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
        width: 100%;
        // height: 20px;
        height: 30px;
    }
    .surveyStatusLabelWarning{
        color: #f44336;
    }
    .surveyStatusItemContainer{
        display: flex;
        align-items: center;
    }
    .surveyStatusChip{
        display: flex;
        align-items: center;
    }
    .surveyChipItem{
        width: 12px;
        height: 2px;
        background-color: #1ac7de;
        margin-right: 2px;
    }
    .surveyChipItemWarning{
        background-color: #f44336;
    }
    .surveyStatusPercent{
        font-size: 17px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.76;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
    }
    .surveyStatusPercent span{
        font-size: 11px;
    }
    

}
.surveyStatusSection .centerStyle{
    display: flex;
    justify-content: center;
    width: 100%;
}
.surveyStatusContainer{
    .dataGatheringSection{
        width:100%;
        height:324px;
        display:flex;
        justify-content: center;
        background-color:#f2fdff;
        box-shadow:0 1px 2px 0 rgba(87, 87, 87, 0.16);
        margin:10px 0 12px 0;
        border-radius:4px;
        border:solid 1px #8ddde6;
    }
    .dataGatheringContent{
        text-align: center;
        cursor: pointer;
    }
    .dataGatheringText{
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.15;
        letter-spacing: normal;
        text-align: center;
        color: #32c2d9;
    }

    @media screen and ( max-width : 1024px){
        .surveyStatusMsgText{
            font-size: 9px;
        }
    }
    @media screen and (min-width:600px) and (max-width:759px){
        .surveyStatusLabel{
            font-size: 12px;
        }
    }
}