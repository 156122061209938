$headerIocnsColor: #ababab;
$notificationCircleColor: #18dc7a;

.header {
    .desktopHeaderIconsWrapper {
        .headerIcon {
            &:hover {
                background-color: transparent;
            }
            margin-left: 10px;
            .MuiSvgIcon-root {
                color: $headerIocnsColor;
                height: auto;
                width: 30px;
            }
            .MuiBadge-badge {
                background-color: $notificationCircleColor;
                color: white;
            }
            &.headerIcon--mod {
                font-size: 0px;
            }
        }

        .MuiBadge-root {
            display: inline-block;
        }
    }
    .toolbarWrapper {
        .hamburgerIcon {
            font-size: 20px;
        }
    }
}
.mobileMenu {
    .mobileMenuItem {
        padding: 0 15px;
        button {
            padding: 0 15px 0 0;
        }
        p {
            margin: 15px 0;
        }
    }
    .avatar {
        margin-right: 10px;
    }
}

@media screen and (min-width: 960px) {
    .desktopMenu {
        .MuiPaper-root {
            top: 54px !important;
        }
    }
}
@media screen and (max-width: 960px) {
    .desktopMenu {
        .MuiPaper-root {
            top: 125px !important;
        }
    }
}

@media screen and (min-width: 1680px) {
    .header {
        .toolbarWrapper {
            .hamburgerIcon {
                font-size: 30px;
            }
        }
    }
}

// @media screen and (min-width: 1920px) {
//     .header {
//         .desktopHeaderIconsWrapper {
//             .headerIcon {
//                 .notificationIcon{
//                     font-size: 30px;
//                 }
//                 .MuiSvgIcon-root {
//                     color: $headerIocnsColor;
//                     height: auto;
//                     width: 43px;
//                 }
//             }
    
//             .MuiBadge-root {
//                 display: inline-block;
//             }
//         }
//         // .toolbarWrapper {
//             // min-height: 103px;
//             // .hamburgerIcon {
//             //     font-size: 40px;
//             // }
//         // }
//     }
// }
