.modalBox{
    position:absolute;
  top:50%;
  left: 50%;
  transform:  translate(-50%, -50%);
  width: 450px;
  background-color: white;
  border-radius:2px;
  /* box-shadow: ; */
  padding:1rem 1rem;

}

.sticky-header tr th {
    position: sticky;
    top: 0;
    background-color: #32c2d9;
    z-index: 1;
  }
  
  .scrollable-body {
    overflow-y: auto;
    max-height: 300px; /* adjust the height to your needs */
  }
.bgColor{
   
    font-size: 1.2rem;
    font-weight: 500; 
}
.bgColor1{
   
    font-size: 1.1rem;
    font-weight: 500; 
}
/* .closeIcon{
    position:absolute;
    top:10px;
    right:10px;
} */
.modalBoxx{
    position:absolute;
  top:25%;
  left: 53%;
  transform:  translate(-50%, -50%);
  width: 60%;

  background-color: white;
  border-radius:2px;
  /* box-shadow: ; */
  padding:1rem 1rem;

}

  
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #000;
  }
  
.denyModalBox{
    position:absolute;
  top:30%;
  left: 53%;
  transform:  translate(-50%, -50%);
  width: 30%;
  /* height: 30%; */
  background-color: white;
  border-radius:2px;
  /* box-shadow: ; */
  padding:1rem 1rem;

}
.modalTitleMatch{
    font-size: 1.2rem;
    font-weight: 500;
}
.modalContent{
    font-size: 1.2rem;
    /* color: ; */
}
/* .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{
    background-color: rgba(0,0,0,0.2) !important;
} */
.matchCancelButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 3rem;
    background-color: #DEDEE1;
    border-radius: 5px;
    color: #8A8FAB;
    font-weight: 600;
  }
.matchSubmitButton{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 3rem;
    background-color: #32c2d9;
    border-radius: 5px;
    color:#fff;;
    font-weight: 600;
}
.emailButton{
    border-top:1px solid #ccc;
    border-Bottom:1px solid #ccc;
    background-Color:#fff;
    margin-Top:20px;
    margin-bottom:20px;
    padding:15px;
}
.matchSubmitButton:hover{
    color: #fff;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked{
    color:#32c2d9 !important;
}

.MuiOutlinedInput-input {
    height: 2em !important;
}

@media only screen and (max-width:600px){
    .modalBox{
        position:absolute;
      top:50%;
      left: 50%;
      transform:  translate(-50%, -50%);
      width: 300px;
      background-color: white;
      border-radius:2px;
      /* box-shadow: ; */
      padding:1.5rem 0.4rem;
    }
    .modalButton{
        width: 100px;
        font-size: 1rem;
    
    }
    .modalContent{
        text-align: center;
    }
}