@import '../../assets/scss/variables.scss';

.customModal{
    position: relative;
    .MuiDialogActions-root{
        padding: 10px 0 18px 0!important;
        display: flex;
        justify-content: space-between;
    }
    .MuiPaper-rounded{
        border-radius: 10px;
    }
    .defaultCloseIcon{
        position: absolute;
        color: $TextMain;
        right: 10px;
        top: 15px;
        .icon{
            font-size: 22px;
            color: $grayLight;
        }
    }
}

.mailModal{
    width:70vw
}