
@import '../../../assets/scss/variables.scss';
$eventFormIconColor :#787878;
$occursDataTextColor : #777777;
$secondaryBlue : $secondaryMain;
$viewEventOccursDataTextDark : #666666;
$eventBackground: #caf3fb;
$eventTextColor: #006070;


    .DraftSummaryTableSection{
        .draftListContent{
            height: calc(100vh - 210px);
            background-color: $colorWhite;
            display: flex;
            justify-content: center;
        }
        .pageWrapper{
            padding: 10px 24px;
        }
        .draftListPage .pageWrapper .pageContentMedium{
            padding-top: 0px !important;
        }
    
        .listClientContent{
            //padding: 20px;
            h3{
                margin-top: 0; 
                margin-bottom: 10px;
                }
        }
        .draftSummaryTableWrapper{
            //padding: 0 20px;
            min-height: 300px;
            position: relative;
            .NoResults{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
            .validationErrorIcon{
                font-size: 18px;
                margin-left: 2px;
                margin-bottom: -4px;
                color: #f44336;
            }

            .mainTable{
                thead{
                    .MuiTableSortLabel-root{
                        font-weight: 600;
                    }
                }
            }
    
        .tableHead{
            background-color: $colorTableHead;
            height: 40px;
            color: $colorWhite;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: left;
            th {
                color: $colorWhite;
                font-size: 14px;
            }
            tr {
                height: 40px;
            }
        }
        tr > th, tr > td{
            padding:8px !important;
        }
    
        .cloneIcon{
            color: #1ac7de;
            font-size: 14px;
            cursor: pointer;
        }
        .activeIcon {
            color: $secondaryBlue;
            font-size: 22px;
            cursor: pointer;
        }
        .transferIcon{
            color: #1ac7de;
            cursor: pointer;
        }
        .transferredItem{
            background: #f5f5f5;
            opacity: 0.7;
        }
            .customTh{
                &.providerTh{
                    min-width:200px;
                    width: 30%;
                }
                &.transferTh{
                    width:60px;
                }
                &.productTypeTh{
                    width:115px;
                }
                &.functionTh{
                    width:80px;
                }
                &.newExistingTh{
                    width:100px;
                }
                &.amountTh{
                    width:80px;
                }
                &.initialTh{
                    width:90px;
                }
                &.ongoingTh{
                    width:95px;
                }
                &.actionTh{
                    width:15px;
                }
            }

        }
        

    .MuiLinearProgress-root{
        height: 5px;
    }
    .MuiLinearProgress-barColorPrimary{
        background-color: $secondaryMain;
    }

    .footerToolbar{
        display: inline-flex;
        padding:18px;
        justify-content: space-between;
    }
    .previousButton{
        width: 118px;
        height: 46px;
        border-radius: 4px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
    }
    .previousButton span.MuiButton-label{
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: $colorBlack;
        justify-content: space-evenly;
    }
    .nextButton{
        width: 106px;
        height: 46px;
        border-radius: 4px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
        background-color: #1ac7de;
    }
    .generateSRButton{
        width: 150px;
    }
    .nextButton span.MuiButton-label{
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        justify-content: space-evenly;
    }
    .nextButton .iconStyle, .previousButton .iconStyle{
        width: 16px;
    }
    td.new{
        color: #1ac7de;
        cursor: pointer;
    }
    .weightedAverage td{
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #1a1a1a;
    }
    .footerInfoBar{
        height: 60px;
        background-color: #f8f8f8;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;

        .subtotalInitialFeeLabelDiv{
            .subtotalInitialFeeLabel{
                // padding: 14px;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color: #1ac7de;
            }
        }
        .subtotalInitialFeeDiv{
            .subtotalInitialFee{
                padding: 6px 7px 5px;
                border-radius: 4px;
                border: solid 1px #d1d1d1;
                background-color: #ffffff;
            }
            .subtotalInitialFeeValue{
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: right;
                color: #1ac7de;
            }
        }
    }
    .footerActionButtonBar{
        height: 80px;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;

        .advisorCommentsDiv{
            display: flex;
            justify-content: flex-start;
        }
        .advisorCommentsDiv, .srButtonDiv{
            .actionButtonLabel{
                width: auto;
                margin-left: 16px;
                padding: 8px 15.7px 8px 16.4px;
                border-radius: 4px;
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
                background-color: #ffffff;
                cursor: pointer;
                display: flex;
                align-items: center;

                .actionButtonIcon{
                    margin-right: 12px;
                    font-size: 14px;
                }
                .notesIcon{
                    color: #ff594d;
                }
                .actionIcon{
                    font-size: 16px;
                    color: #1ac7de;
                    margin-right: 12px;
                }
                .actionButtonLabelText{
                    align-items: center;
                    font-size: 14px;
                    font-weight: 500 !important;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.14;
                    letter-spacing: normal;
                    text-align: left;
                    color: #1ac7de;
                }
                .notesText{
                    color: #ff594d;
                }
            }
            
            .linkBtn{
                text-decoration: none;
            }
            .actionFilename{
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.64;
                letter-spacing: normal;
                text-align: center;
                color: #959595;
                padding-left: 20px;
                cursor: default;
            }
        }
        .srButtonDiv{
            display: flex;
            justify-content: flex-end;
            .actionButtonLabel{
                margin-left: 0px;
                margin-right: 16px;
                display: flex;
                align-items: center;
            }
            .actionButtonLabel.generateSR{
                background-color: #1ac7de;
                width: 146px;
                text-align: center;
                justify-content: center;
                .actionButtonLabelText{
                    color: #ffffff;
                }
                .CircularLoader{
                    height: 16px;
                }
            }
        }
        .srRegenerateHint{
            margin-left: 16px;
            font-size: 12px;
            font-style: italic;
        }
    }
}
.draftListWrapper{
    .snackNotification .MuiSnackbarContent-root .MuiSnackbarContent-action{
        display: none !important;
    }
}
.productInfoModal{
    .MuiDialog-paperWidthSm{
        max-width: 720px !important;
    }
    .customModalWrapper .MuiDialogContent-root{
        overflow: hidden;
    }
    .productInfoForm{
        width: 100%;
        .fieldLabel{
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
        }
        .buttonContainer{
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        .sliderComponent{
            margin-top: 19px;
        }
        .reasonLoader{
            display: inline-flex;
        }
        .MuiTextField-root{
            margin-top: 8px;
            margin-bottom: 4px;
        }
    }
    .productInfo{
        padding: 0 10px 24px 10px;

        .MuiSlider-root{
            color: #1ac7de !important;
        }
        .MuiSlider-valueLabel{
            left: calc(-50% - 0px) !important;
            font-size: 9px !important;
            top: -20px !important;
            span{
                width: 24px !important;
                height: 24px !important;
                display: flex;
                align-items: center;
                border-radius: 50% 50% 50% 0;
                justify-content: center;
            }
        }
    }
}
.commentsModal{
    .modalHeading{
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
        display: flex;
        .commentsLoader{
            margin-left: 10px;
        }
    }
    .commentsForm{
        width: 100%;
        .advisorComments{
            padding: 20px 0;;
        }
        .commentsTextarea{
            padding-bottom:16px;
        }
    }
    .buttonContainer{
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
}
.notesModal{
    .modalHeading{
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.89;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
    }
    .notesContainer{
        font-size: 14px;
        .notesList > li{
            color: #1ac7de;
            content:"•";
            font-size:24px;
        }
        .listHeading{
            font-weight: 500;
            color: #707070;
        }
        .listText{
            color: #707070;
            font-size: 1rem;
            font-weight: 400;
        }
        // .notesList > li:before{ content:"•"; font-size:40px; }
    }

}
@media screen and ( max-width : 960px)
{
    .DraftSummaryTableSection{
        .footerActionButtonBar{
            .advisorCommentsDiv, .srButtonDiv{
                margin-bottom: 10px;
            }
        }
        .footerToolbar{
            padding-top: 24px;
        }
    }
}