@import '../../../assets/scss/variables.scss';


.addCalendarEventModal{
    .errorMessages{
        margin-left: 8px;
    }
}
.fileAttachments{
    overflow-y: auto;
}
.addCalendarEventModal .customModalWrapper{
    padding: 20px 5px;
}
.viewCalendarEventModal .modalDetails{
    padding: 10px 15px 20px 30px;
}
.info_icon{
   color:$secondaryMain;
}
.attachmentIcon{
    cursor: pointer;
}

.addCalendarEventModal{
    .attachIcon{
        display: flex;
        align-items: center;
        p{
            margin-bottom: 0;
            margin-top: 0;
            margin-left: 10px;
        }
    }
    .attachmentSizeExceedMsg{
        color: $error;
        font-size: 11px;
    }
    .dialogueWrapper{
        overflow: hidden;
    }
}


@media screen and ( max-width : 962px)
{
    
    .addCalendarEventModal{
        .attachmentIconWrapper{
            margin-bottom: 20px;
        }
        .customModalWrapper{
            padding:5px 5px;
        }
        .formItemWrapper{
            padding-left: 30px;
        }
    }
}


@media screen and ( max-width : 599px)
{
    .addCalendarEventModal{
        .modalFooterButtons{
            justify-content: space-between;
            button{
                margin-left: 0!important;
                margin-bottom: 15px;
                width: 100%!important;
            }
        }
    }
}

