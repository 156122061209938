@import '../../assets/scss/variables.scss';

.nameCircle{
    border-radius: 50%;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    background: $secondaryMain;
}