
.SearchableSelect{
    .searchableSelectResults{
        display: flex;
    }
    .resultIcon{
        display: inline-block;
        margin-right: 10px;
    }
    .highLighter{
        display: inline-block;
        margin-left: 10px;
    }
}
.MuiOutlinedInput-input{
    height: 12px !important;
}