@import '../../assets/scss/variables.scss';
.processStageWrapper{
    // .accordionHead .MuiAccordionSummary-content{
    //     align-items: center !important;
    // }
    // .accordionHead p{
    //     margin-left: 10px;
    // }
    .accordionContainer{

    }
    .accordionHeader{

    }
    .accordionContent{
        height: 354px;
        overflow-y: auto;
            .subheading {
              cursor: pointer;
              display: flex;
              align-items: center;
          
              svg {
                margin-right: 5px;
                transition: transform 0.6s;
              }
            }
          
            .content {
              margin-left: 20px;
            }
    }
    .accordionHead{
        height: 45px;
        background-color: $colorLightBlue;
        color: $colorDarkGrey;
    }
    .accordionHead p {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: $colorDarkGrey;
        margin-left: 10px;
    }
    .accordionHeadCompleted{
        height: 45px;
        background-color: $secondaryMain;
        color: $colorWhite;
    }
    .accordionHeadCompleted p {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: $colorWhite;
        margin-left: 10px;
    }
    .accordionHeadActive{
        height: 45px;
        background-color: $colorLightBlue;
        color: $colorDarkOrange;
    }
    .accordionHeadActive p {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: $colorDarkOrange;
        margin-left: 10px;
    }

    .accordionHeadFirst{
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .accordionHeadLast{
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
  
    .accordionHead .MuiAccordionSummary-expandIcon{
        color: $colorDarkGrey;
    }
    .accordionHeadCompleted .MuiAccordionSummary-expandIcon{
        color: $colorWhite;
    }
    .accordionHeadActive .MuiAccordionSummary-expandIcon{
        color: $colorDarkOrange;
    }
}