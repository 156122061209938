@import '../../assets/scss/variables.scss';

.customTable {
    &.customTableStyles {
        min-height: 324px;
        thead {
            .MuiTableSortLabel-root {
                font-weight: 600 !important;
            }
            tr {
                th {
                    font-weight: 500;
                    border-bottom: 1px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                }
            }
        }

        .MuiTableBody-root {
            .MuiTableRow-root {
                th,
                td {
                    font-size: 14px;
                }
            }
        }
    }

    .removeLastRowBorder{
        .MuiTableRow-root {
            // for removing last item border bottom
            &:last-child {
                th,
                td {
                    border-bottom: 1px solid transparent;
                }
            }
        }
    }


    .blueHeader{
        thead{
            background: $secondaryMain;
            th{
                color: white;
            }
        }
    }

    .borderedTable{
        border: 1px solid rgba(224, 224, 224, 1);
    }
    
}





// @media screen and (min-width : 1921px)
// {
//     .customTable {
//         &.customTableStyles {
//             thead {
//                 .MuiTableSortLabel-root {
//                     font-weight: 600 !important;
//                 }
//                 tr {
//                     th {
//                         font-size: 20px;
//                         font-weight: 500;
//                         padding: 25px 16px;
//                     }
//                 }
//             }
    
//             .MuiTableBody-root {
//                 .MuiTableRow-root {
//                     th,
//                     td {
//                         padding: 28px 16px;
//                         font-size: 20px;
//                     }
//                 }
//             }
//         }
 
//     }    
// }