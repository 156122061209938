.SearchClientScreen{
    height: 75vh;
    /* width: 85vw; */
    background-color: #EDF1F2;
    position: relative;
}
.SearchClientBox{
    position:absolute;
  top:50%;
  left: 50%;
  transform:  translate(-50%, -50%);
  width: 630px;
  background-color: white;
  border-radius:2px;
  /* box-shadow: ; */
  padding:3rem 1rem;
  box-shadow: #ccc 4px;
}
.searchBtn{
    padding: 5px 25px;
    background-color: #32C2D9;
    font-size: 1rem;
    color: white;
    border: none;
    border-radius: 3px;
}
.textFieldContainer{
    padding:2rem 1rem;
}

@media only screen and (max-width:600px) {
    .SearchClientBox{
        width: 100vw;
        padding: 3rem 0.5rem;
    }
}