
/* 
@font-face {
  font-family: 'poppins-regular';
  src: url('./Fonts/Poppins/Poppins-Regular.ttf');
  font-weight: normal;
  font-style: normal;
} */

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


html *
{
   
   /* font-family: poppins-regular ; */
   font-family: 'Roboto', sans-serif;
}


