@import '../../assets/scss/variables.scss';

.notAuthorizedMessage{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    .heading{
        margin-bottom: 10px;
        padding: 0 10px;
    }
    .subHeading{
        margin-top: 0;
        padding: 0 10px;
    }
    .icon{
        &.blockIcon{
            color: $error;
            font-size: 120px;
        }
    }
    .backBtn{
        width: 200px;
    }
}