.NoResults{
    display: flex;
    flex-direction: column;
    align-items: center;
    .noResultsIcon{
        margin-bottom: 0;
        width: 50px;
        height: auto;
        display: block;
    }
    p{
        margin-top: 0px;
    }
}