@import '../../assets/scss/variables.scss';
.documentListTable{
    .documentListName{
        color: $secondaryMain;
        display: flex;
        align-items: center;
        .icon{
            margin-right: 10px;
        }
        border-bottom: none;
        .documentIcon{
            font-size: 18px;
        }
        .infoTooltip{
            
        }
    }
    .documentListActions{
        color: $secondaryMain;
        display: flex;
        align-items: center;
        border-bottom: none;
        span{
            margin-left: 10px;
            display: flex;
            align-items: center;
            .icon{
                font-size: 18px;
            }
            &:first-child{
                margin-left: 0;
            }
        }
    }

    .documentDetailsWrapper{
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        td{
            color: $grayLight;
            padding: 12px 6px;
        }
    }
    .tableHead{
        border-bottom: 2px solid rgba(224, 224, 224, 1);
        th{
            padding: 12px 6px;
        }
    }
    
    
}




//  previos document list styles 
// .documentList{
//     .listContainer{
//         border-bottom: 1px solid #e1e1e1;
//         padding-bottom: 0;
//     }
//     .listItemContainer{
//         padding-left: 10px;
//         padding-right: 10px;
//         border-top: 1px solid #e1e1e1;
//         .MuiListItemText-root {
//             font-size: 14px;
//             font-weight: 500;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: 1.86;
//             letter-spacing: normal;
//             text-align: left;
//             color: #1ac7de;
//         }
//         .MuiListItemIcon-root{
//             color: #1ac7de;
//             min-width: 36px;
//         }
//         .listItemPrimaryDetails{
//             display: flex;
//         }
//         .listItemSecondaryDetails{
//             display: flex;
//             margin-left: auto;
//         }
//     }
//     .secondaryAction{
//         font-size: 12px;
//         font-weight: normal;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: 2.17;
//         letter-spacing: normal;
//         text-align: left;
//         color: $grayLight;
//     }
//     .listItemSubHead{
//         margin-top: 0;
//         margin-bottom: 0;
//         &.listItemSubHeadMarginRight{
//             margin-right: 15px;
//         }
//     }
//     .iconWrapper{
//         span{
//             display: flex;
//             align-items: center;
//             .icon{
//                 font-size: 18px;
//             }
//         }
//     }

   
    
// }

// @media screen and (max-width:500px)
// {
//     .documentList{
//         .listItemContainer{
//             flex-direction: column;
//             align-items: flex-start;
//             .MuiListItemIcon-root{
//                 color: #1ac7de;
//                 min-width: 36px;
//             }
//             .listItemPrimaryDetails{
//                 display: flex;
//             }
//             .listItemSecondaryDetails{
//                 display: flex;
//                 margin-left: 0;
//             }
//         }
//     }
// }
