@import "../../../assets/scss/variables.scss";

.dashboardContent {
  .responsiveTable {
    overflow-y :auto;
    max-height: 452px;
  }

  .percentageTextWrapper {
    h1 {
      text-align: center;
      color: $dashboardBlue;
      font-size: 80px;
      font-weight: 400;
      padding: 30px 0;
      margin: 0;
    }
  }
}


@media screen and (max-width: 1282px)
{
  .dashboardContent {
    .responsiveTable {
      max-height: none;
    }
  }
}