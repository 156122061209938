@import "../../assets/scss/variables.scss";

.sub-items{
    display: flex;
    flex-direction: column;
    align-items: center;

    a{
        outline:none !important;
        text-decoration: none !important;
    }
}

.sub-item{
    display: flex;
    align-items: center;
    width:40%;
    cursor: pointer;
}

.item-label{
    margin:0;
    font-size: 14px;
    color: #FFFFFF;
    font-family: $generalFontFamily;
    font-weight: 700;
    text-decoration: none;
    outline: none !important;
    text-transform: capitalize;
    outline-width: 0 !important;
}