@import '../../assets/scss/variables.scss';

$iconColor : #959595;
$hightlightColor : #F2FDFF;
.fileBrowser{
    $lightGray : rgb(226, 226, 226);
    .fileBrowserHeaderText{
        font-size: 18px;
        text-transform: capitalize;
    }
    .fileBrowserBreadcrumb{
        padding-left: 0;
        li{
            user-select: none;
            list-style: none;
            font-weight: 500;
            display: inline-block;
            cursor: pointer;
            &:before{
                content : "/";
                display: inline-block;
                margin: 0 7px;
            }
            
            &:last-child{
                &:before{
                    color: $TextMain;
                }
                color: $secondaryMain;
            }
            &:first-child{
                &:before{
                    display: none;
                }
                color: $TextMain;
            }
        }
    }
    .fileBrowserHeader{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $lightGray;
        p{
            color: #7a7a7a;
            &.fileBrowserSize{
                font-weight: 500; 
            }
            margin-bottom: 10px;
            user-select: none;
        }
    }


    .fileItemsWrapper{

        .fileBrowserItem{
            border-bottom: 1px solid $lightGray;
            display: flex;
            align-items: center;
            padding: 10px 0;
            justify-content: space-between;
            &:last-child{
                border-bottom: 1px solid transparent;
            }
            p{
                margin: 0;
                user-select: none;
            }
            .fileBrowserItemData{
                display: flex;
                align-items: center;
            }
            .fileBrowserItemSize{
                padding-right: 15px;
                color: #7a7a7a;
            }
        }
    }

    .hightLight{
        background: $hightlightColor;
    }

    .fileBrowserIcon{
        margin-right: 10px;
        color: $secondaryMain;
        
    }
}