@import "../../assets/scss/variables.scss";



.composerWrapper {
    position: relative;
    // padding: 50px;
    .ck.ck-editor {
        position: static;
    }
    .ck-editor__top {
        padding-left: 12px;
        padding-right: 12px;
        // position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        top: 0;
        border-bottom: 1px solid $grayMain;
    }
    .header {
        padding-top: 40px;
    }
    .ck-toolbar {
        background: transparent;
        border: 1px solid transparent!important;
    }
    .ck-toolbar__items {
        padding-bottom: 7px;
    }
    // for removing border of editable area
    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
        border: none;
    }
    // for removing border of editable area on focus
    .ck-focused {
        border: none !important;
        box-shadow: none !important;
    }
}
