$generalFontFamily : 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

$primaryMain: #463c97;
$primaryLight: #5245bb;
$primaryDark: #2b2082;

$secondaryMain: #32c2d9;
$secondaryLight: #f3fdff;
$secondaryDark: #0ca4b8;

$TextMain: rgba(0, 0, 0, 0.87);
$TextSub: #6e6e6e;


$CardCurve: 4px;

$grayDark: #686868;
$grayMain: #edf1f2;
$grayLight: #9a9a9a;

$error: #f44336;
$success: #66d478;
$InProgress: #ff9800;
$Labels: #6e6e6e;


$mailReadCount : #CEF8FE;
$pageWrapperBackground: #EDF1F2;


$dashboardBlue : #1AC7DE;
$dashboardOrange : #F1CC3B;

$secondaryEmeraldBlue:#02cbd0;

$secondaryBlue: #32c2d9;
$colorWhite: #ffffff;
$colorBlack: #000000;
$colorGrey: #686868;
$colorLightGrey: #d5d5d5;
$colorOrange: #ffb83e;
$colorTableHead: #1ac7de;
$colorTextGrey: #6a6a6a;
$colorDarkOrange: #ff8000;
$colorLightBlue: #f2fdff;
$colorDarkGrey: #434343;
$colorIconInactive: #dddddd;