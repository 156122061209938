$lightGrayBlue : #9CB9BE;

.clientProfilePageContent{
    .profileExpand{
        background: white;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .addCircle{
            color: $lightGrayBlue;
            font-size: 22px;
        }
    }
    @media screen and ( max-width : 1279px){
        .profileExpand{
            align-items: flex-end;
            .profileExpandIconContainer{
                min-height: 80px;
            }
        }
    }
    .titleText h5{
        margin:0;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: left;
        color: #484848;
        padding-top: 15px;
        padding-bottom: 10px;
    }
    .aboutText{
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
    }
    .expandContainer{
        border-top: 1px solid #e1e1e1;
        padding: 10px 24px;
    }
    .referredTitle{
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
    }
    .reffereddetailsWrapper{
        display: flex;
        justify-content: left;
        align-items: flex-start;
    }
    .reffereddetailsCircle{
        $height:42px;
        width: $height;
        height: $height;
        border-radius: 50%;
        // background: red;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
    }
    .reffereddetailsRight{
        padding-left: 15px;
        // display: flex;
        align-items: center;
    }
    .refferediconColor{
        background-color: #1ac7de;
    }
    .reffereddetailsHead{
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
        height: 17px;
    }
    .reffereddetailsData{
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.17;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
        height: 17px;
    }
    .referredTitle h5{
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
        margin-bottom: 6px;
    }
    .referredNameText{
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.89;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
    }
    .accordionDiv{
        box-shadow:none;
        border-bottom:1px solid #e1e1e1;
    }
    .buttonContainer{
        height: 80px;
        border-radius: 4px;
        box-shadow: 0 1px 2px 0 rgba(87, 87, 87, 0.16);
        border: solid 1px #e1e1e1;
        background-color: #ffffff;
    }

    .buttonContainerBottom{
        height: 65px;
        border-radius: 4px;
        box-shadow: 0 1px 2px 0 rgba(87, 87, 87, 0.16);
        border: solid 1px #e1e1e1;
        background-color: #ffffff;
    }
    .clientProfileAboutSection{
        background-color:#fff;
        padding: 24px 0px 0px 12px;
    }
    .aboutContainer{
        padding: 0 14px;
    }
    .buttonContainerInner{
        display: flex;
        justify-content: center;
        border-right: solid 1px #e1e1e1;
        .productCountLoader{
            align-self: center;
        }
    }
    .buttonContainerInnerContent{
        color:#32c2d9;
        display: flex;
        align-items: center;
    }
    .productLink1{
        padding-left:12px;
        cursor: pointer;
        display:flex;
        font-size: 13px;
        color: #6a6a6a;
    }
    .productLink2{
        padding-left: 12px;
        cursor: pointer;
        display: flex;
        font-weight: 500;
    }
    .calendarLinkContainer{
        display: flex;
        justify-content: center;
        .nextMeetingLoader{
            align-self: center;
        }
    }
    .calendarLink{
        color: #32c2d9;
        display: flex;
        align-items: center;
        //font-weight: 500;
    }
    .calendarLinkText{
        padding-left: 12px;
        cursor: pointer;
    }
    .dataGatheringSection{
        width:100%;
        height:300px;
        display:flex;
        justify-content: center;
        background-color:#f2fdff;
        box-shadow:0 1px 2px 0 rgba(87, 87, 87, 0.16);
        margin:10px 0 12px 0;
        border-radius:4px;
        border:solid 1px #8ddde6;
    }
    .buttonContainerBottom{
        margin-bottom:12px;
    }
    .trackButtonContainer{
        display: flex;
        justify-content: center;
        border-right:solid 1px #e1e1e1;
    }
    .trackButtonContent{
        color:#32c2d9;
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: 14px;
    }
    .trackButtonText{
        padding-left:12px;
        cursor:pointer;
        display:flex;
    }
    .pendingActionContainer{
        display: flex;
        justify-content: center
    }
    .pendingActionContent{
        color:#32c2d9;
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: 14px;
    }
    .pendingButtonText{
        padding-left:12px;
        cursor:pointer;
        display:flex;
        font-size: 13px;
        font-weight: normal;
        color: #6a6a6a;
    }
    .pendingButtonText2{
        padding-left:12px;
        cursor:pointer;
        display:flex;
        font-size: 13px;
        font-weight: normal;
        color: #f44336;
    }
    .dataGatheringContent{
        text-align: center;
        cursor: pointer;
    }
    .dataGatheringContent p{

    }
    .dataGatheringText{
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.15;
        letter-spacing: normal;
        text-align: center;
        color: #32c2d9;
    }
    .innerLoader{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 85vh;
    }
    .clientInfoLoader{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 20px;
    }
}



