$regularfontblack : #535353;


.ClientInfoBar{
    .detailsWrapper{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-right: 10px;
        // padding-left: 5px;
        .detailsCircle{
            $height:42px;
            width: $height;
            height: $height;
            border-radius: 50%;
            // background: red;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
        }
        .detailsRight{
            padding-left: 10px;
            display: flex;
            align-items: center;
            width: 100%;
            .detailsHead{
                display: block;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: #6e6e6e;
                // &.detailsHead{
                //     color: $regularfontblack;
                //     margin-bottom: 0px;
                //     line-height: initial;
                // }
            }
            .detailsData{
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                color: #000000;
            }
            .detailsRightWrapper{
                width: calc(100% - 5%);
            }
            .emailDetails{
                width: calc(100% - 1%);
                // overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                display: block;
                word-break: break-word;
            }
        }
    }
    .ClientInfoBarPaper{
        padding: 10px 0px 10px 24px;
        .emailGrid{
           width: 500px;
        }
    }
    @mixin commonCircle{
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 3.13;
        letter-spacing: normal;
        color: #ffffff;
        cursor: pointer;
    }
    .vulnerable-restricted{
        @include commonCircle;
        background-image: linear-gradient(to right,#e65050ff, #e6c250ff );
    }
    .vulnerable{
        @include commonCircle;
        background-color: #e65050ff;
    }
    .restricted{
        @include commonCircle;
        background-color: #e6c250ff;
    }
    .detailsCircleName{
        @include commonCircle;
        background-color: #32c2d9;
    }
    .detailsWrapperUser{
        margin-bottom: 10px;
        .detailsName{
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #1a1a1a;
            line-height: 20px;
            cursor: pointer;
            word-break: break-word;
        }
        .detailsProfession{
            display: block;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #484848;
            word-break: break-word;
        }
        .detailsLocation{
            display: block;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #777777;
        }
    }
    @media screen and ( max-width : 960px)
    {
        .detailsWrapper{
            justify-content: start;
            &.detailsWrapperUser{
                justify-content: center;
            }
        }
       
    }
    @media screen and ( max-width : 1279px)
    {
        .detailsWrapper{
            margin-bottom: 15px;
        }
    }
}

.expandContainer{
    .documentWrapper{
        height: 320px;
        overflow-y: auto;
    }
    .button-download{
        background-color: white;
        color: #32c2d9;
        border-width: 0px;
        &:hover{
            background-color: white;
        }
    }
    .referredByBlock{
        .reffereddetailsWrapper{
            .reffereddetailsRight.nameOnly{
                display: flex;
                height: 34px;
            }
        }
    }
}

.buttonAction{
  
    color: #32c2d9 !important;
    background-color: rgba(245, 245, 245, 0) !important;
    border: 1px solid #32c2d9 !important;

}

.buttonContainerssh{
    height: 0px !important;
    border: none !important;
}

.matchBtn{
    width: 100px !important;
    margin-left: 40px !important;
}


@media screen and (max-width:600px)
{
    .expandContainer{
        .reffereddetailsWrapper {
            margin-bottom: 15px;
        }
        .bottomSectionHeading {
            margin-bottom: 10px!important;
        }
    }
}