@import '../../../assets/scss/variables.scss';

$eventFormIconColor :#787878;
$occursDataTextColor : #777777;
$secondaryBlue : $secondaryMain;
$viewEventOccursDataTextDark : #666666;
$eventBackground: #caf3fb;
$eventTextColor: #006070;


.calendarPage{
    .calendar {
        font-family: $generalFontFamily;
        background: white;
        padding-top: 20px;
        border-radius: 7px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #4d4f5c;
        .fc {
            // Month view styles starts  --------- starts
    
            // for capitalising week days text
            .fc-scrollgrid {
                thead {
                    text-transform: uppercase;
                    .fc-col-header-cell-cushion {
                        padding-top: 4px;
                        font-weight: 500;
                    }
                }
            }
            // for changing event style
            .fc-daygrid-event {
                padding: 3px 4px;
                i {
                    font-style: normal;
                }
            }
    
            // for moving toolbars
            .fc-header-toolbar {
                padding-right: 20px;
                padding-left: 20px;
            }
    
            // for giving padding outside of calendar box
            .fc-daygrid {
                padding: 0 17px 17px 17px;
            }
    
            // reducing default font weight of title
            .fc-toolbar-title {
                font-size: 24px;
                line-height: 0.42;
                font-weight: normal;
            }
    
            // remove bootstrap like boxshadow on focus
            .fc-button {
                &:focus {
                    box-shadow: none !important;
                }
            }
            .fc-day-today {
                background-color: #f5f6fa;
            }
    
            // Month view styles starts  --------- ends
    
            // Week view styles starts  --------- starts
    
            .fc-timegrid-event {
                .fc-event-main {
                    i {
                        font-style: normal;
                    }
                }
            }
            .fc-timegrid-col-frame{
                .fc-timegrid-col-events{
                    .fc-timegrid-event-harness{
                        min-height: 30px;
                    }
                }
            }
    
            // Week view styles starts  --------- ends
    
            // List view styles starts  --------- starts
    
            // list view event tile italic removing
            .fc-list-event-title {
                i {
                    font-style: normal;
                }
                .eventitemStarttime{
                    display: none;
                }
                .eventItemTitle{
                    font-size: 18px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 0.89;
                    letter-spacing: normal;
                    text-align: center;
                    color: #4d4f5c;
                }
            }
    
            // list view day bar background
            .fc-cell-shaded {
                background-color: #f5f6fa;
            }
    
            .fc-list-day-side-text {
                font-weight: 400;
            }
            .fc-daygrid-day .fc-event{
                // background-color: $eventBackground !important;
                color: $eventTextColor !important;
                height: 33px;
                display: flex;
                align-items: center;
            }
            .fc-today-button{
                //font-size: 12px;
                font-weight: 500;
                //line-height: 1.17;
                color: #ffffff;
                text-transform: uppercase;
            }
            .fc-list-day-text{
                font-size: 18px;
                line-height: 0.89;
                font-weight: normal;
                color: #4d4f5c;
            }
            .fc-list-event-dot{
                //width: 15px;
                //height: 15px;
                background-color: #26a13e;
                border-color: #26a13e !important;
            }
            .fc-event-main, .eventItem {
                width: 100%;
            }
            .eventItemTitle{
                padding: 0px 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                width: calc(100% - 20px);
            }
            // .fc-event-main{
            //     color: $eventTextColor !important;
            // }
            // Month view styles starts  --------- ends
        }

        // Calendar custom styles starts


        // styling calendar event in month view

        .customCalendarView{
            .calendarEvent{
                border-radius: 0px!important;
                margin-bottom: 2px;
                margin-left: 0!important;
            }
            &.calendarInMonthView{
                .monthViewEvent{
                    .eventItem{
                        display: flex;
                        justify-content: space-between;
                        p{
                            margin-top: 0;
                            margin-bottom: 0;
                        }
                        .eventItemIcon{
                            font-size: 14px;
                        }
                        .eventDescription{
                            display: none;
                        }
                    }
                }
            }
            &.calendarInWorkWeekView,&.calendarInDayView,&.calendarInWeekView{
                .workWeekViewEvent,.dayViewEvent,.weekViewEvent{
                    $color: #0C6978;
                    padding: 4px;
                    // border:none!important;
                    // border-left: 3px solid $color!important;
                    // background-color: #CAF3FB!important;
                    
                    // Note :: consider making this dynamic
                    &.first{
                        border-left: 3px solid #006070!important;
                    }
                    &.second{
                        border-left : 3px solid #ff1493!important;
                    }
                    &.fourth{
                        border-left : 3px solid #008e1c!important;
                    }
                    &.other{
                        border-left : 3px solid #ff8000!important;
                    }
                    
                    .eventItem{
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;
                        min-height: 28px;
                        // p{
                        //     color: $color;
                        // }
                        .eventDescription{
                            display: block;
                            padding-right: 20px;
                            color: $color;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    .eventitemStarttime{
                        display: none;
                    }
                    .attachementIcon{
                        position: absolute;
                        right: 0px;
                        bottom: 0px;
                        // color: $color;
                    }
                }


                .fc-daygrid-body{
                    .fc-event{
                        height: auto;
                        .eventItemTitle {
                            white-space: nowrap;
                            width: calc(100% - 20px);
                        }
                    }        
                }
            }

            &.calendarInListView{
                .listViewEvent{
                    .eventItem{
                        .attachementIcon{
                            display: none;
                        } 
                    }
                }
            }

            
        }



        // on initial load styling calendar items classnames only gets geenrated when view is changed initialy custom classnames will not be in the dom
        .eventItem{
            display: flex;
            justify-content: space-between;
            p{
                margin-top: 0;
                margin-bottom: 0;
                font-weight: 500;
            }
            .eventItemIcon{
                font-size: 14px;
            }
            .eventDescription{
                display: none;
            }
        }


        .fc-popover-body {
            max-height: 400px;
            overflow: scroll;
            overflow-x: hidden;
        }

        .workWeekViewEvent .eventItem, .weekViewEvent .eventItem, .dayViewEvent .eventItem{
            height: 100%;
        }
    }


    #screenRotateMessage{
        position:fixed;
        top:0; 
        left:0;
        text-align:center; 
        background:white; 
        width:100%; 
        height:100%;
        display:none;
        justify-content: center;
        align-items: center;
        right: 0;
        bottom: 0;
        z-index: 9999;
        padding: 20px 50px;
        line-height: 2;
        flex-direction: column;
        .rotateIcon{
            font-size: 28px;
            color: $grayLight;
        }
    }
    .MuiDialog-paperWidthSm{
        max-width: 710px !important;
    }




    /* portrait */
    @media screen and (orientation:portrait) {
        #screenRotateMessage{
            display: flex;
        }
    }
    /* landscape */
    @media screen and (orientation:landscape) {
        #screenRotateMessage{
            display: none;
        }
    }


}



// --- Starts  : Add event modal styles  --

.addCalendarEventModal{
    &.customModal{
        .modalFooter{
            padding-left: 40px!important;
            .modalFooterWrapper{
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                align-items: center;
                .attachIcon{
                    color: $grayLight;
                }
                .calendarBtn{
                    margin-left: 20px;
                    text-transform: inherit!important;
                }
            }
        }
        
    }
    .modalHeader{
        width: 100%;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 0px;
        .modalHeaderExpand{
            margin-right: 10px;
            color: white;
        }
    }

    .eventDescriptionComposer{
        border: 1px solid rgb(195, 195, 195);
        border-radius: 4px;
    }

    .formItemWrapper{
        padding-left: 45px;
        .eventItemWrapper{
            position: relative;
            .eventModalItemIcon{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -30px;
                color: $eventFormIconColor;
                &.eventModalItemIcon--mod{
                    top: 10%;
                    transform: translateY(0%);
                }
            }
        }
    }
    
    // .expandedContent{
    //     padding-left: 20px;
    // }

    .occursData{
        margin: 0;
        color: $occursDataTextColor;
        .occursDataItem{
            display: inline-block;
            margin-right: 5px;
        }
        .occursUntilText{
            margin-right: 5px;
            // margin-left: 5px;
        }
    }

    .plainDatePickerWrapper{
        position: relative;
        .plainDatePicker{
            width: 114px;
            &>div{
                // display: none;
                &:before,&:after{
                    display: none!important;
                }
                input[name="until"]
                {
                    padding: 0;
                    font-size: 14px;
                    cursor: pointer;
                    color: $secondaryBlue;
                }
                position: relative;
                top: 2px;
                font-size: 14px;
                margin-top: 0;
            }
            
            z-index: 3;
            
        }
        .plainDatePickerOpenIcon{
            color: $secondaryBlue;
            position: absolute;
            right: -2px;
            // top: -3px;
            z-index: 2;
            margin-right: 10px;
            font-size: 20px;
        }
    }


    .endDateManage{
        color: $secondaryBlue;
        display: inline-block;
    }



    // increasing size of calendar popup

    .MuiPaper-root{
        max-height : calc(100% - 5px);
    }

    .calendarBtn{
        text-transform: uppercase!important;
    }
}

// --- Ends  : Add event modal styles  -- 



// --- Starts  : View calendar event item modal styles -- 

.viewCalendarEventModal{
    .viewCalendarEventModalIcon{
        color: $occursDataTextColor;
    }
    .MuiDialogContent-root{
        padding: 0;
    }

    .modalDetails{
        h1{
            margin-top: 15px;
            margin-bottom: 15px;
            font-weight: normal;
            font-size: 18px !important;
            color: $TextMain;
        }
        padding: 0px 25px;
        .left{
            .divider{
                height: 1px;
                display: block;
                background: rgb(224, 224, 224);
                margin-bottom: 15px;
            }
            .modalDetailsItem{
                display: flex;
                align-items: center;
                margin-top: 0;
                &.modalDetailsItem--mod{
                    align-items: flex-start;
                }
                &.modalDetailsLocation{
                    word-break: break-all;
                    
                }
            }
            .viewCalendarEventModalIcon{
                margin-right: 10px;
            }
        }
        .right{
            padding-left: 15px;
            h4{
                margin-bottom: 10px;
            }


            .userContainer{
                display: flex;
                align-items: center;
                padding: 3px 0;
                margin-bottom: 10px;
                p{
                    margin: 0;
                    width: 165px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .meetingAttendeeContainer{
                overflow-y: auto;
                max-height: 250px;
                //  accordion
                .meetingAttendeeItem{
                    box-shadow: none;
                    .MuiCollapse-container{
                        .MuiAccordionDetails-root{
                            padding: 8px 0 0 0 ;
                        }
                    }
                    .accordionHead{
                        min-height: 30px;
                        padding-left: 0;

                        flex-direction: row-reverse;
                        align-items: center;
                        justify-content: space-between;

                        .MuiAccordionSummary-expandIcon{
                            padding-left: 0;
                            padding-right: 0;
                            margin-right: 5px;
                        }

                        .MuiAccordionSummary-content{
                            margin: 6px 0;
                        }
                        .MuiIconButton-root{
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                        &.Mui-expanded {
                            margin: 0;
                            min-height: 0;
                            .MuiAccordionSummary-content{
                                margin: 6px 0;
                            }
                            .MuiIconButton-root{
                                padding-top: 0;
                                padding-bottom: 0;
                            }
                        }
                        
                        .head{
                            margin-top: 0;
                            margin-bottom: 0;
                            color: $secondaryMain;
                            font-weight: 500;
                        }
                    }
                    &:before{
                        background: transparent;
                    }
                    
                }
            }
        }
        .organizerHeading{
            margin-top: 0;
        }
    }


    &.viewCalendarEventNotByMe{
        .modalDetails{
            .left{
                .userContainer{
                    display: flex;
                    align-items: center;
                    padding: 3px 0;
                    margin-bottom: 10px;
                    p{
                        margin: 0;
                    }
                    .respondData{
                        .respondOrganizer{
                            font-weight: 700;
                        }
                        .respondStatus{
                            font-size: 12px;
                            color: $grayLight;
                        }
                    }
                }
            }
        }
    
    }





    .customModalFooter{
        padding: 15px 15px 15px 50px;
        background: $secondaryLight;
        display: flex;
        color: $secondaryMain;
        span{
            font-size: 18px;
            display: flex;
            align-items: center;
            line-height: 1.22;
            .viewCalendarEventModalIcon{
                color: $secondaryMain;
                margin-right: 10px;
                font-size: 20px;
            }
            margin-right: 31px;
        }
        
    }

    position: relative;
    .closeIcon{
        position: absolute;
        top: 13px;
        right: 13px;
        font-size: 18px;
        z-index: 999;
    }

    .descriptionHtmlContent p{
        margin: 0;
        // word-break: break-all;
        word-wrap: break-word
    }



    // For keeping header and footer fixed on top and bottom
    &.fixedHeaderFooter{
        
        .modalHeaderWrapper{
            .customHead{
                position: absolute;
                background: white;
                top: 0;
                width: 100%;
                margin-top: 0;
                padding: 15px 30px;
                z-index: 3;
            }
            .duplicatecustomHeadAbsoluteAdjuster{
                height: 55px;
            }
            .duplicateHeaderAdjuster{
                height: 50px;
            }
        }
        .modalDetails{
            max-height: calc( 100vh - 180px);
            overflow-x: hidden;
            overflow-y: scroll;
        }

        .descriptionHtmlContent{
            padding-right: 15px;
            max-height: 130px ;
            overflow-y: auto;
            overflow-x: hidden;
            width: 100%;
        }
        .right{
            max-height: calc( 100vh - 100px);
            overflow-y: auto;
            overflow-x: hidden;
        }

        ::-webkit-scrollbar{
            width: 5px;
        }


        .fileAttachmentPreview{
            .fileAttachments{
                max-height: 155px;
            }
        }
    }




}

// --- Ends  : View calendar event item modal styles -- 



.personCircle{
    $size: 30px;
    height: $size;
    width: $size;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ECECEC;
    margin-right: 10px;
}

.rsvpDisabled{
    color: #26a13e !important;
}
.cancelledEvent{
    text-decoration: line-through;;
}
.eventActionFooter{
    justify-content:flex-end !important;
}
.MuiLinearProgress-barColorPrimary{
    background-color: #32c2d9;
}
@media screen and (max-width:600px)
{
    .viewCalendarEventModal{
        .modalDetails{
            .right{
                padding-left: 0;
            }
        }
    }
    .right .userContainer p{
        width: 100%;
    }
}


// .fc-popover{
//     .fc-daygrid-event-harness{
//         .fc-daygrid-event{
//             background: #CAF3FB!important;
//             margin-bottom: 2px;
//             color: #006070;
//         }
//     }
// }


// .fc-daygrid-day-bottom{
//     position: relative;
//     top: -25px;
//     left: 0;
//     margin-top: 0!important;
// }


/* 
    For positioning '+2 more' label text on top of the box
*/
// .fc .fc-daygrid-day-bottom{
//     position: absolute;
//     z-index: 4;
//     font-size: .85em;
//     margin: 2px 3px 0;
//     width: auto;
//     top: -22px;
//     left: 0;
//     margin-top: 0 !important;
// }



@media screen and (max-width : 1140px)
{
    .calendarPage{
        .fc-header-toolbar{
            display: flex;
            flex-direction: column;
            .fc-toolbar-chunk:nth-child(2)
            {
                margin: 20px 0;
            }
        }
    }
}


@media screen and (max-width : 960px)
{
    .modalDetails{
        .right{
            margin-top: 20px;
        }
    }
    
}