@import "./assets/scss/variables.scss";

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-documenteditor/styles/material.css";

// --- Starts : For removing black outline of chrome from all elements --

*,
input,
select,
textarea,
option,
button {
    outline: none !important;
}

// --- Starts : For removing black outline of chrome from all elements --



// --- Starts  : Utility classes --

.cursor-pointer {
    cursor: pointer;
}

.border {
    border: 1px solid grey;
}
.d-none {
    display: none !important;
}
.d-block{
    display: block!important;
}

.zeroMargin{
    margin-top: 0!important;
    margin-bottom: 0!important;
}


.center-stuff {
    display: flex;
    justify-content: center;
    align-items: center;
}

// this class is not used anywhere in project this is only for testing purposes
.container {
    padding-left: 100px;
    padding-right: 100px;
}
// --- Ends  : Utility classes --







// --- starts  : styling the session time out modal --

.timeOutModal {
    .timeOutModalContent {
        text-align: center;
    }
    .timeOutIcon {
        height: auto;
        width: 90px;
    }
    .modalFooter {
        button {
            margin: 0 auto;
        }
    }
}

// --- Ends  : styling the session time out modal --







// --- starts  : Styling snackbar --

.snackNotification {
    .icon{
        margin-right: 15px;
    }
    .successIcon {
        color: $success !important;
    }
    .errorIcon{
        color: $error !important;
        font-size: 20px;
    }
    .warningIcon{
        color: $InProgress !important;
    }
    .MuiSnackbarContent-action {
        margin-left: 0;
    }
    .snackCloseButton {
        background: transparent !important;
        padding: 0 10px 0 0 !important;
        min-width: auto;
        .closeIcon {
            color: white;
            height: auto;
            width: 20px;
        }
    }
    .MuiSnackbarContent-root{
        background: rgb(49, 49, 49)!important;  // this will override success,error and all 
        display: flex;
        justify-content: space-between;
        .MuiSnackbarContent-message{
            width: 90%;
        }
        .MuiSnackbarContent-action{
            width: 8%;
            padding-left: 0;
        }
    }

}

@media screen and (min-width:600px)
{
    .snackNotification .MuiSnackbarContent-root{
        min-width: 380px;
    }
}

// --- Ends  : Styling snackbar --




// --- starts  : custom scroll bar styles --

.customScrollbar {

    ::-webkit-scrollbar-button:start:decrement , 
    ::-webkit-scrollbar-button:end:increment { display: none; }

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
        height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $grayLight;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: $grayDark;
    }

    scrollbar-color:#888 #f1f1f1;
    scrollbar-width: thin;


}


// --- Ends  : custom scroll bar styles --




// --- Starts  : Common generalized heading styles -



// h1 client management
.customHead{
    font-size: 18px!important;
    font-weight: 500!important;
}

// h2 : recenttly added
.customSubHead{
    font-size: 16px!important;
    font-weight: 500!important;
}


.customSubHead2{
    font-size: 14px!important;
    font-weight: 500!important;
}


// regular p tags
.customDescription{
    font-size: 14px!important;
    font-weight: 400!important;
}


.customSmallText{
    font-size: 12px!important;
}




// table head
.customTh{
    font-size: 14px!important;
    font-weight: 500!important;
}
// table data

.customTd{
    font-size: 14px!important;
    font-weight: 400!important;
}







// --- Ends  : Common generalized heading styles -- 



// --- Starts  : Button with no style -- 

.noStyleButton{
    // all: unset;
    background: none;
	color: inherit;
	border: none;
	font: inherit;
    outline: inherit;
    box-shadow: none;
}

// --- Ends  : Button with no style -- 




legend{
    float: unset !important;
}