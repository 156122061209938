.AddClientScreen{
    background-color: #EDF1F2;
    height: 100%;
    padding: 0.5rem 2rem;
    padding-top: 18px;
}
.AddClientBox{
    background-color: white;
    border-radius: 2px;
    padding: 1.5rem 2rem;
    margin-top: 10px;
}
.AddBtn{
    padding: 5px 25px;
    background-color: #32C2D9;
    font-size: 1rem;
    color: white;
    border: none;
    border-radius: 3px;

}
.AddClientTitle{
   color: #32C2D9;
}
.addClientPageHeading{
    font-size: 17px;
    margin-top: 10px;
    font-weight: 500;
}