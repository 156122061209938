@import "../../assets/scss/variables.scss";

.MuiOutlinedInput-input {
    padding: 12px 16px !important;
    font-size: 12px !important;
    font-family: $generalFontFamily !important;
}

.content-body {
    height: 100%;
    background-color: #fff;
}

.top-bar {
    background-color: #F6FDFF;
    width: 100%;
    padding: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    column-gap: 10px;
}

.clear-btn {
    cursor: pointer;
    width: 120px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 2px;
    display: grid;
    place-content: center;
    color: #fff;
    background: tomato;
    font-size: 12px;
    border-radius: 2px;
    &:hover{
        background: #A7A7A7;
    }
}

.title {
    font-size: 12px;
    font-weight: 700;
    color: #3E3348;
    font-family: $generalFontFamily;
    margin: 0
}

.text-style {
    font-size: 12px;
    font-family: $generalFontFamily;
    color: #636065;
    margin: 0
}

.sort-btn {
    border-radius: 2px;
    // width: 16px;
    // height: 17px;
    cursor: pointer;
    // display: grid;
    // place-content: center;
    font-size: 9px;
    color: #fff;
    font-family: $generalFontFamily;
    padding: 4px 7px
}

.sort-btn-2 {
    border: 1px solid #848484;
    border-radius: 2px;
    width: 29px;
    height: 20px;
    cursor: pointer;
    display: grid;
    place-content: center;
    font-size: 12px;
    color: #848484;
    font-family: $generalFontFamily;
}

.tile-title {
    font-size: 14px;
    color: red !important;
}

.tooltip-custom {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 12px #0000005E;
    border-radius: 5px 5px 0px 5px;
    opacity: 1;
    padding: 12px 17px;
    display: flex;
    align-items: flex-start;
    width: auto;
    justify-content: space-between;
}

.tooltip-name {
    font-size: 10px;
    color: #171F33;
    font-family: $generalFontFamily;
    font-weight: 800;
    margin-bottom: 2px;

}

.tooltip-aum {
    font-size: 10px;
    color: #F6424E;
    font-family: $generalFontFamily;
    font-weight: 800;
}

.tooltip-right {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    align-items: flex-end;
    justify-content: flex-start;
}

.tooltip-datefield {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}

.tooltip-label {
    font-size: 8px;
    color: #A7A7A7;
    font-family: $generalFontFamily;
    font-weight: 500;
}

.tooltip-value {
    font-size: 8px;
    color: #171F33;
    font-family: $generalFontFamily;
    font-weight: 800;
}

.tooltip-relation{
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.loader {
    width: 100%;
    display: grid;
    place-content: center;
    padding: 20px;

    p {
        color: tomato;
        font-size: 18px;
        font-family: $generalFontFamily;
    }
}

#myRect{
    max-width: 500px;
    min-width: 200px;
}