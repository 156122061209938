.notAuthorizedMessageWrapper{
    height: 100%;
    width: 100%;
    &.centerContent{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .notAuthorizedMessageContent{
        margin: 0;
    }
}