@import "../../assets/scss/variables.scss";

.errorBoundary {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: white;
  .heading {
    margin-bottom: 10px;
    padding: 0 10px;
  }
  .subHeading {
    margin-top: 0;
    padding: 0 10px;
    text-align: center;
  }
  .icon {
    &.errorIcon {
      color: $error;
      font-size: 120px;
    }
  }
  .backBtn {
    width: 200px;
  }
}
