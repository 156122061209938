@import '../../assets/scss/variables.scss';
.viewMore{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $secondaryBlue;
    cursor: pointer;
    text-align: right;
    align-content: flex-end;
    float:right;
}