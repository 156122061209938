
@import '../../assets/scss/variables.scss';
.timelineWrapper{

    padding-right: 0;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;

    .MuiTimelineItem-missingOppositeContent:before{
        display: none;
    }
    .itemHeading{
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: left;
        color: $colorBlack;
        // margin-top: 9px;
    }
    .itemSubHeadingFeedback{
        font-size: 15px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: left;
        color: $colorTextGrey;
        display: flex;
        // margin-top: 16px;
       
        align-items: center;
        margin-left: 67px;
    }
    .itemSubHeading{
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: left;
        color: $colorTextGrey;
        display: flex;
        align-items: center;
        gap:16px;
    }
    .itemSubHeadingGrade{
        font-size: 14px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: left;
        display: flex;
        align-items: center; 
    }
   .green{
    color: #66d478;
   }
   .orange{
    color:#f99600 ;
   }
   .red{
    color:#FF0000 ;
   }
    .grade{
        color: $colorBlack;
        font-size: 14px;
        font-weight: 700;
        // margin-top: 18px;
    }
    .itemSubIcon{
        width: 12px;
        color: $secondaryMain;
        margin-right: 10px;
    }
    .itemIconDone{
        background-color: $secondaryMain;
        color: $colorWhite;
    }


    .accordionContentAdjust{
        padding-top: 15px;
        // margin-top: 30px;
    }
    .redirectLink{
        color: $secondaryMain;
        cursor: pointer;
    }
    .innerLoader{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    @media screen and (min-width:600px) and (max-width:800px){
        .timelineItem{
            margin-bottom: 8px;
        }
        .itemHeading{
            font-size: 12px;
        }
        .MuiTimelineSeparator-root{
            min-height: 80px;
        }
    }
}