.selectBox{
    position:absolute;
  top:50%;
  left: 50%;
  transform:  translate(-50%, -50%);
  width: 550px;
  background-color: white;
  border-radius:2px;
  /* box-shadow: ; */
  padding:3rem 2rem;
  box-shadow: #ccc 4px;
}
.selectTitle{
    font-size: 1.5rem;
}
.selectContent{
    font-size: 1.2rem;
    /* color: ; */
}
.selectButtons{
    padding: 1rem 2rem; 
}
.selectButton{
    background-color: #32C2D9;
    border-radius: 5px;
    border: none;
    padding: 5px 0 5px 0;
    width: 150px;
    font-size: 1.1rem;
    color: white;
}
.selectButtonText{
  
    margin: 0;
   
}
.selectClientScreen{
    height: 80vh;
    width: 100%;
    position: relative;
    background-color: #EDF1F2;
}
@media only screen and (max-width:600px){
    .selectBox{
        position:absolute;
      top:50%;
      left: 50%;
      transform:  translate(-50%, -50%);
      width: 300px;
      background-color: white;
      border-radius:2px;
      /* box-shadow: ; */
      padding:1.5rem 0.4rem;
    }
    .selectButton{
        width: 100px;
        font-size: 1rem;
    
    }
    .selectContent{
        text-align: center;
    }
    .SearchClientBox{
        width: 100vw;
    }
    
}